<template>
  <div class="job-posting-form">
    <form @submit.prevent="submitJobPosting">
      <div class="form-group">
        <label for="title">Job Title</label>
        <input 
          v-model="jobPosting.title" 
          type="text" 
          id="title" 
          required 
        />
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea 
          v-model="jobPosting.description" 
          id="description" 
          required
        ></textarea>
      </div>
      <div class="form-group">
        <label for="location">Location</label>
        <input 
          v-model="jobPosting.location" 
          type="text" 
          id="location" 
          required 
        />
      </div>
      <div class="form-group">
        <label for="salary">Salary</label>
        <input 
          v-model="jobPosting.salary" 
          type="number" 
          id="salary" 
          step="0.01" 
          required 
        />
      </div>
      <button type="submit" :disabled="isSubmitting">
        {{ isSubmitting ? 'Submitting...' : 'Post Job' }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'JobPostingComponent',
  data() {
    return {
      jobPosting: {
        title: '',
        description: '',
        location: '',
        salary: null
      },
      isSubmitting: false,
      error: null
    }
  },
  methods: {
    async submitJobPosting() {
      // Reset error state
      this.error = null;
      this.isSubmitting = true;

      try {
        // Get access token from cookie
        const accessToken = Cookies.get('access_token');

        // Check if access token exists
        if (!accessToken) {
          throw new Error('No access token found. Please log in.');
        }

        // Submit job posting
        const response = await axios.post(
          'https://api-jobs-dev.talentbase.lk/api/jobposting/', 
          this.jobPosting,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // Handle successful submission
        this.$emit('job-posted', response.data);
        
        // Reset form
        this.jobPosting = {
          title: '',
          description: '',
          location: '',
          salary: null
        };

        // Optional: Show success message
        alert('Job posting submitted successfully!');
      } catch (error) {
        // Handle errors
        console.error('Job posting failed:', error);
        this.error = error.response?.data || error.message;
        
        // Optional: Show error message
        alert(`Failed to post job: ${this.error}`);
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.job-posting-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>