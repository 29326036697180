<template>
  <div class="home-container">
    <div class="hero-section">
      <h1>Welcome to Career Coach</h1>
      <p class="subtitle">Discover majors and in-demand careers and education based on your interests!</p>
    </div>
    
    <div class="options-container">
      <!-- Assessment Card -->
      <div class="option-card">
        <h2>Take Career Assessment</h2>
        <p>
          Take a Career Assessment to learn about yourself and Career Coach will give you career 
          suggestions based on your interests.
        </p>
        <button class="primary-button" @click="navigateToAssessment">Take the Assessment</button>
      </div>
      
      <!-- Careers Card -->
      <div class="option-card">
        <h2>Browse Careers</h2>
        <p>
          Browse or search for careers and we will give you relevant data on wages, employment, and
          the training you need
        </p>
        <div class="search-container">
          <input type="text" class="search-input" placeholder="Search for Careers">
          <button class="search-button">
            <i class="search-icon">🔍</i>
          </button>
        </div>
        <p class="browse-link">
          Or <a href="#" @click="navigateToCareers">Browse all Careers</a>
        </p>
      </div>
      
      <!-- Programs Card -->
      <div class="option-card">
        <h2>Browse Programs</h2>
        <p>
          Browse or search for the available Programs that lead to the career you want.
        </p>
        <div class="search-container">
          <input type="text" class="search-input" placeholder="Search for Programs">
          <button class="search-button">
            <i class="search-icon">🔍</i>
          </button>
        </div>
        <p class="browse-link">
          Or <a href="#" @click="navigateToPrograms">Browse all Programs</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    navigateToAssessment() {
      this.$router.push('/assessment');
    },
    navigateToCareers() {
      this.$router.push('/careers');
    },
    navigateToPrograms() {
      this.$router.push('/programs');
    }
  }
}
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
}

.hero-section {
  text-align: center;
  margin: 30px 0;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 1.2rem;
  max-width: 700px;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.option-card {
  background-color: white;
  color: #333;
  border-radius: 8px;
  padding: 25px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.primary-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin-top: 20px;
}

.search-container {
  display: flex;
  margin-top: 20px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.search-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 0 15px;
  cursor: pointer;
}

.browse-link {
  margin-top: 10px;
  font-size: 0.9rem;
}

.browse-link a {
  color: #4CAF50;
  text-decoration: none;
}
</style>