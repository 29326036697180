<template>
  <div class="container">
    <aside class="sidebar">
      <ul>
        <li @click="navigateToHome">Home</li>
        <li @click="navigateToOccupations">Occupations</li>
        <li @click="navigateToAlumni">Alumni</li>
        <li>Programs</li>
        <li>Job Postings</li>
        <li>Industries</li>
        <li>Regions</li>
        <li>Input-Output</li>
        <li>State System</li>
        <li>Profile Analytics</li>
        <li>Alumni Pathways</li>
        <li>Data Download</li>
      </ul>
    </aside>

    <div class="content">
      <h2>Popular Job Postings Reports</h2>
      <div class="reports">
        <div class="report-card">
          <h3>Job Posting Analytics</h3>
          <p>An overview of job posting activity over time with data related to intensity, regions, occupations, job titles, and skills.</p>
        </div>
        <div class="report-card">
          <h3>Job Postings Table</h3>
          <p>A table displaying job posting activity over time.</p>
          <span class="bookmark">Add Bookmark</span>
        </div>
        <div class="report-card">
          <h3>Job Postings by Location</h3>
          <p>A map of job posting activity comparing intensity and variability across regions.</p>
        </div>
      </div>

      <h2>All Other Job Postings Reports</h2>
      <div class="reports">
        <div class="report-card">
          <h3>Report Builder</h3>
          <p>Build a custom view of job postings data.</p>
        </div>
        <div class="report-card">
          <h3>Job Postings List</h3>
          <p>Get a list of all available job postings matching your search criteria, including full text for each.</p>
        </div>
        <div class="report-card">
          <h3>Company Talent Profile</h3>
          <p>An overview of a company with data relating to the jobs it employs, the jobs it is hiring, and talent competitors.</p>
        </div>
      </div>
    </div>

    <button class="knowledge-base">Knowledge Base</button>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToHome() {
      this.$router.push('/');
    },
    navigateToAlumni() {
      this.$router.push('/alumni');
    },
    navigateToOccupations() {
      this.$router.push('/occupations');
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  background: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
}

.sidebar li:hover {
  color: #007bff;
}

.content {
  flex: 1;
  padding: 20px;
}

h2 {
  margin-bottom: 10px;
}

.reports {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.report-card {
  width: 30%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
}

.bookmark {
  display: block;
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.knowledge-base {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.knowledge-base:hover {
  background: #0056b3;
}

.navbar {
  display: flex;
}

.nav-link {
  padding: 10px;
  text-decoration: none;
  color: black;
}

.nav-link:not(:last-child) {
  border-right: 1px solid #ccc; /* Add a line between the links */
  margin-right: 10px; /* Adjust space between the links */
}

</style>
