<template>
  <div class="job-board">
    <!-- Header -->
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="logo">
            <img src="/logo.png" alt="TalentBase" />
          </div>
          <nav class="main-nav">
            <ul>
              <li><a href="/" class="nav-link">HOME</a></li>
              <li><a href="#" class="nav-link active">JOB CARDS</a></li>
              <li><a href="#" class="nav-link">COURSES</a></li>
              <li><a href="#" class="nav-link">ABOUT US</a></li>
              <li><a href="#" class="nav-link">PRICING</a></li>
            </ul>
          </nav>
          <div class="action-buttons">
            <button class="btn btn-post">POST JOB</button>
            <div class="language-select">EN-US</div>
            <button class="btn btn-sign-in">
              <i class="fas fa-sign-in-alt"></i> SIGN IN
            </button>
            <button class="btn btn-signup">
              <i class="fas fa-user-plus"></i> JOB SEEKER SIGNUP
            </button>
          </div>
        </div>
      </div>
    </header>

    <!-- Banner -->
    <div class="banner">
      <div class="container">
        <h1>Job Cards</h1>
        <div class="breadcrumbs">
          <a href="#">HOME</a> <i class="fas fa-chevron-right"></i> <span>JOB CARDS</span>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <div class="container">
        <!-- Search Filters -->
        <div class="search-filters">
          <div class="search-input">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="Keywords, Job title, Skills" v-model="searchQuery" />
          </div>
          
          <div class="location-filter dropdown">
            <i class="fas fa-map-marker-alt"></i>
            <div class="dropdown-toggle" @click="toggleLocationDropdown">
              {{ selectedLocation }}
              <i class="fas fa-chevron-down"></i>
            </div>
            <div class="dropdown-menu" v-if="showLocationDropdown">
              <div class="dropdown-item" @click="selectLocation('Colombo')">Colombo</div>
              <div class="dropdown-item" @click="selectLocation('Galle')">Galle</div>
              <div class="dropdown-item" @click="selectLocation('Kandy')">Kandy</div>
            </div>
          </div>
          
          <div class="category-filter dropdown">
            <i class="fas fa-briefcase"></i>
            <div class="dropdown-toggle" @click="toggleCategoryDropdown">
              {{ selectedCategory }}
              <i class="fas fa-chevron-down"></i>
            </div>
            <div class="dropdown-menu" v-if="showCategoryDropdown">
              <div class="dropdown-item" @click="selectCategory('Accounting')">Accounting</div>
              <div class="dropdown-item" @click="selectCategory('IT')">IT</div>
              <div class="dropdown-item" @click="selectCategory('Management')">Management</div>
            </div>
          </div>
          
          <button class="btn btn-search" @click="searchJobs">
            <i class="fas fa-search"></i> SEARCH
          </button>
          
          <div class="view-options">
            <button class="btn btn-view" :class="{ active: viewMode === 'list' }" @click="setViewMode('list')">
              <i class="fas fa-list"></i> LIST VIEW
            </button>
            <button class="btn btn-view" :class="{ active: viewMode === 'grid' }" @click="setViewMode('grid')">
              <i class="fas fa-th"></i> GRID VIEW
            </button>
          </div>
        </div>
        
        <div class="advanced-filter-container">
          <button class="btn btn-filter" @click="toggleAdvancedFilters">
            <i class="fas fa-filter"></i> ADVANCED FILTERS
          </button>
        </div>
        
        <!-- Job Listings -->
        <div class="job-listings" :class="viewMode">
          <div class="job-card" v-for="job in jobs" :key="job.id">
            <div class="job-logo">
              <img :src="job.logo || '/placeholder.png'" alt="Company Logo" />
            </div>
            <div class="job-details">
              <h3 class="job-title">{{ job.title }}</h3>
              <div class="job-company">
                <span class="company-name">{{ job.company }}</span>
                <span class="job-location"><i class="fas fa-map-marker-alt"></i> {{ job.location }}</span>
              </div>
              <div class="job-description" v-html="truncateDescription(job.description)"></div>
              <div class="job-meta">
                <span class="job-type">{{ job.job_type }}</span>
                <span class="job-time"><i class="fas fa-clock"></i> {{ job.time_since }}</span>
              </div>
            </div>
            <div class="job-actions">
              <div class="job-salary">
                <span class="currency">{{ job.salary_currency }}</span> {{ job.salary || 'Negotiable' }}/Month
              </div>
              <div class="job-experience">
                <i class="fas fa-briefcase"></i> {{ job.experience_needed || 0 }}
              </div>
              <!-- Changed to anchor tag pointing to job URL -->
              <a :href="job.url" target="_blank" class="btn btn-apply">APPLY NOW</a>
              <button class="btn btn-save" @click="toggleSaveJob(job)">
                <i class="fas" :class="job.saved ? 'fa-heart' : 'fa-heart-o'"></i>
              </button>
              <button class="btn btn-save" @click="toggleSaveJob(job)">
                <i class="fas" :class="job.saved ? 'fa-heart' : 'fa-heart-o'"></i>
              </button>
            </div>
          </div>
        </div>
        
        <!-- Pagination -->
        <div class="pagination">
          <button class="btn-page" :disabled="currentPage === 1" @click="goToPage(currentPage - 1)">
            <i class="fas fa-chevron-left"></i>
          </button>
          <button 
            v-for="page in displayedPages" 
            :key="page" 
            class="btn-page" 
            :class="{ active: currentPage === page }" 
            @click="goToPage(page)"
          >
            {{ page }}
          </button>
          <button class="btn-page" :disabled="currentPage === totalPages" @click="goToPage(currentPage + 1)">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      selectedLocation: 'Colombo',
      selectedCategory: 'Accounting',
      showLocationDropdown: false,
      showCategoryDropdown: false,
      showAdvancedFilters: false,
      viewMode: 'list',
      currentPage: 1,
      totalPages: 10,
      jobs: [],
      isLoading: false
    }
  },
  computed: {
    displayedPages() {
      const pages = [];
      const maxDisplayedPages = 5;
      
      if (this.totalPages <= maxDisplayedPages) {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (this.currentPage <= 3) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i);
          }
        } else if (this.currentPage >= this.totalPages - 2) {
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
            pages.push(i);
          }
        } else {
          for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
            pages.push(i);
          }
        }
      }
      
      return pages;
    }
  },
  created() {
    this.fetchJobs();
  },
  methods: {
    fetchJobs() {
      this.isLoading = true;
      
      // Simulating API call
      setTimeout(() => {
        fetch(`https://api-jobs-dev.talentbase.lk/api/jobs/?page=${this.currentPage}&location=${this.selectedLocation}&category=${this.selectedCategory}&q=${this.searchQuery}`)
          .then(response => response.json())
          .then(data => {
            this.jobs = data.results.map(job => ({
              ...job,
              saved: false,
              logo: '/placeholder.png'
            }));
            this.totalPages = Math.ceil(data.count / 10);
          })
          .catch(error => {
            console.error('Error fetching jobs:', error);
            // Fallback to sample data if API call fails
            this.loadSampleData();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 500);
    },
    loadSampleData() {
      this.jobs = [
        {
          id: 1,
          title: "Executive Administrative Assistant",
          company: "Secretary",
          location: "Colombo",
          description: "Job description Company Description We suggest you enter details here. Role Description This is a full-time on-site role for an Executive Administrative Assistant located in Colombo. The role will involve providing administrative assistance, demonstrating phone etiquette, maintaining effective communication, utilizing executive administrative assistance skills, and demonstrating clerical skills. Qualifications • Administrative Assistance and Executive Administrative Assistance skills • Effective Phone Etiquette and...",
          url: "#",
          posted_at: "2025-02-27T08:33:39.464409Z",
          job_type: "Full Time",
          salary: "80.00",
          salary_currency: "LKR",
          experience_needed: 0,
          time_since: "2 days ago",
          saved: false,
          logo: '/placeholder.png'
        },
        {
          id: 2,
          title: "Administrative Executive",
          company: "Ayang Kaalo",
          location: "Colombo",
          description: "Job description Role Description This is a full-time on-site role for an Administrative Executive located in Colombo. The Administrative Executive will be responsible for executive administrative assistance, general administration tasks, and providing administrative support. The role will require strong interpersonal skills and effective communication with various stakeholders. Qualifications • Executive Administrative Assistance and Administrative Assistance skills • Day to day management of accounting...",
          url: "#",
          posted_at: "2025-02-26T10:15:20.464409Z",
          job_type: "Full Time",
          salary: "80.00",
          salary_currency: "LKR",
          experience_needed: 0,
          time_since: "3 days ago",
          saved: false,
          logo: '/placeholder.png'
        }
      ];
      this.totalPages = 5;
    },
    searchJobs() {
      this.currentPage = 1;
      this.fetchJobs();
    },
    goToPage(page) {
      if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchJobs();
      }
    },
    toggleLocationDropdown() {
      this.showLocationDropdown = !this.showLocationDropdown;
      this.showCategoryDropdown = false;
    },
    toggleCategoryDropdown() {
      this.showCategoryDropdown = !this.showCategoryDropdown;
      this.showLocationDropdown = false;
    },
    selectLocation(location) {
      this.selectedLocation = location;
      this.showLocationDropdown = false;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.showCategoryDropdown = false;
    },
    toggleAdvancedFilters() {
      this.showAdvancedFilters = !this.showAdvancedFilters;
    },
    setViewMode(mode) {
      this.viewMode = mode;
    },
    truncateDescription(description) {
      if (description && description.length > 300) {
        return description.substring(0, 300) + '...';
      }
      return description;
    },
    applyForJob(jobId) {
      console.log(`Applying for job ${jobId}`);
      // Implementation for job application
    },
    toggleSaveJob(job) {
      job.saved = !job.saved;
      console.log(`Job ${job.id} ${job.saved ? 'saved' : 'unsaved'}`);
    }
  }
}
</script>

<style scoped>
.job-board {
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
}

/* Header Styles */
.header {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  height: 40px;
}

.main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  padding: 10px 15px;
  transition: color 0.3s;
}

.nav-link.active, .nav-link:hover {
  color: #f08c00;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.btn-post {
  background-color: #f08c00;
  color: white;
}

.btn-sign-in {
  background-color: #f08c00;
  color: white;
}

.btn-signup {
  background-color: #f08c00;
  color: white;
}

.language-select {
  margin-right: 10px;
  color: #f08c00;
  font-weight: 500;
}

/* Banner Styles */
.banner {
  background-color: #f08c00;
  color: white;
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}

.banner::after {
  content: '';
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: white;
  border-radius: 50% 50% 0 0;
}

.banner h1 {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
}

.breadcrumbs {
  margin-top: 10px;
  font-size: 14px;
}

.breadcrumbs a {
  color: white;
  text-decoration: none;
}

.breadcrumbs i {
  margin: 0 10px;
  font-size: 12px;
}

/* Main Content Styles */
.main-content {
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Search Filters */
.search-filters {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.search-input {
  flex: 1;
  position: relative;
}

.search-input input {
  width: 100%;
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-input i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.dropdown {
  position: relative;
  min-width: 180px;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 10;
  margin-top: 5px;
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.btn-search {
  background-color: #f08c00;
  color: white;
  padding: 10px 20px;
}

.view-options {
  display: flex;
  gap: 10px;
}

.btn-view {
  background-color: #f5f5f5;
  color: #333;
  padding: 10px 15px;
}

.btn-view.active {
  background-color: #f08c00;
  color: white;
}

.advanced-filter-container {
  margin-bottom: 20px;
}

.btn-filter {
  background-color: #fff;
  color: #f08c00;
  border: 1px solid #f08c00;
  padding: 8px 16px;
}

/* Job Listings */
.job-listings {
  margin-bottom: 30px;
}

.job-card {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.job-logo {
  width: 100px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.job-logo img {
  max-width: 100%;
  max-height: 100%;
}

.job-details {
  flex: 1;
  padding: 15px;
}

.job-title {
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
}

.job-company {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.company-name {
  font-weight: 500;
  margin-right: 15px;
}

.job-location {
  color: #666;
}

.job-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.5;
}

.job-meta {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
}

.job-type {
  margin-right: 15px;
  padding: 3px 8px;
  background-color: #e0f7fa;
  color: #0097a7;
  border-radius: 3px;
}

.job-actions {
  width: 250px;
  padding: 15px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.job-salary {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  color: #f08c00;
  margin-bottom: 10px;
}

.currency {
  font-size: 12px;
}

.job-experience {
  margin-bottom: 15px;
  font-size: 14px;
  color: #666;
}

.btn-apply {
  background-color: #f08c00;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}

.btn-save {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 5px;
}

.btn-save:hover {
  color: #f08c00;
}

/* Grid View */
.job-listings.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.job-listings.grid .job-card {
  flex-direction: column;
  height: 100%;
}

.job-listings.grid .job-logo {
  width: 100%;
  height: 120px;
}

.job-listings.grid .job-actions {
  width: 100%;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.btn-page {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.btn-page.active {
  background-color: #f08c00;
  color: white;
  border-color: #f08c00;
}

.btn-page:hover:not(.active):not(:disabled) {
  background-color: #f5f5f5;
}

.btn-page:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive */
@media (max-width: 992px) {
  .job-card {
    flex-direction: column;
  }
  
  .job-logo {
    width: 100%;
    height: 120px;
  }
  
  .job-actions {
    width: 100%;
  }
  
  .search-filters {
    flex-direction: column;
  }
  
  .dropdown, .search-input {
    width: 100%;
  }
  
  .view-options {
    width: 100%;
    justify-content: space-between;
  }
  
  .btn-view {
    flex: 1;
  }
}
</style>