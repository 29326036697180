<template>
  <header class="site-header">
    <div class="container">
      <div class="header-content">
        <!-- Logo -->
        <!-- <div class="logo">
          <router-link to="/">
            <img src="/logo.png" alt="Site Logo" class="logo-image" />
            <span class="logo-text">EduPortal</span>
          </router-link>
        </div> -->

        <!-- Navigation -->
        <nav class="main-nav">
          <ul class="nav-links">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/courses" class="nav-link">Courses</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact</router-link>
            </li>
          </ul>
        </nav>

        <!-- Auth Buttons -->
        <div class="auth-buttons">
          <router-link to="/signin" class="btn btn-signin">Sign In</router-link>
          <router-link to="/signup" class="btn btn-signup">Sign Up</router-link>
        </div>

        <!-- Mobile Menu Toggle -->
        <div class="mobile-menu-toggle" @click="toggleMobileMenu">
          <i class="fas fa-bars"></i>
        </div>
      </div>

      <!-- Mobile Menu (Hidden by default) -->
      <div class="mobile-menu" :class="{ 'is-active': mobileMenuOpen }">
        <ul class="mobile-nav-links">
          <li class="mobile-nav-item">
            <router-link to="/" class="mobile-nav-link" @click="closeMobileMenu">Home</router-link>
          </li>
          <li class="mobile-nav-item">
            <router-link to="/courses" class="mobile-nav-link" @click="closeMobileMenu">Courses</router-link>
          </li>
          <li class="mobile-nav-item">
            <router-link to="/about" class="mobile-nav-link" @click="closeMobileMenu">About</router-link>
          </li>
          <li class="mobile-nav-item">
            <router-link to="/contact" class="mobile-nav-link" @click="closeMobileMenu">Contact</router-link>
          </li>
          <li class="mobile-nav-item auth">
            <router-link to="/signin" class="mobile-nav-link" @click="closeMobileMenu">Sign In</router-link>
          </li>
          <li class="mobile-nav-item auth">
            <router-link to="/signup" class="mobile-nav-link signup" @click="closeMobileMenu">Sign Up</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderList',
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      
      // Prevent scrolling when mobile menu is open
      if (this.mobileMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
      document.body.style.overflow = '';
    }
  }
}
</script>

<style scoped>
.site-header {
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

/* Logo */
.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  color: #405066;
}

/* Navigation */
.main-nav {
  display: flex;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 15px;
}

.nav-link {
  color: #405066;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 0;
  position: relative;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ee7f22;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ee7f22;
  transition: width 0.3s;
}

.nav-link:hover::after,
.router-link-active::after {
  width: 100%;
}

/* Auth Buttons */
.auth-buttons {
  display: flex;
  gap: 15px;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.btn-signin {
  color: #ee7f22;
  border: 1px solid #ee7f22;
  background-color: transparent;
}

.btn-signin:hover {
  background-color: rgba(238, 127, 34, 0.1);
}

.btn-signup {
  color: white;
  background-color: #ee7f22;
  border: 1px solid #ee7f22;
}

.btn-signup:hover {
  background-color: #d6711e;
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none;
  font-size: 24px;
  color: #405066;
  cursor: pointer;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: white;
  z-index: 99;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.mobile-menu.is-active {
  transform: translateX(0);
}

.mobile-nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-item {
  margin-bottom: 15px;
}

.mobile-nav-link {
  display: block;
  padding: 15px 0;
  color: #405066;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid #eee;
}

.mobile-nav-item.auth {
  margin-top: 20px;
}

.mobile-nav-link.signup {
  color: #ee7f22;
  font-weight: 600;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .main-nav {
    display: none;
  }

  .auth-buttons {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .mobile-menu {
    display: block;
  }
}
</style>