<template>
  <div class="signin-container">
    <div class="signin-card">
      <h1>Sign In</h1>
      
      <div v-if="error" class="error-message">
        {{ error }}
      </div>
      
      <form @submit.prevent="handleSignIn" class="signin-form">
        <div class="form-group">
          <label for="email">Email</label>
          <input 
            type="email" 
            id="email" 
            v-model="credentials.email" 
            placeholder="Enter your email"
            required
          />
        </div>
        
        <div class="form-group">
          <label for="password">Password</label>
          <input 
            type="password" 
            id="password" 
            v-model="credentials.password" 
            placeholder="Enter your password"
            required
          />
        </div>
        
        <button type="submit" class="signin-button" :disabled="loading">
          {{ loading ? 'Signing in...' : 'Sign In' }}
        </button>
      </form>
      
      <div class="additional-options">
        <p>Don't have an account? <router-link to="/signup">Sign Up</router-link></p>
        <p><router-link to="/forgot-password">Forgot Password?</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignInPage',
  data() {
    return {
      credentials: {
        username: '',
        password: '',
        email: ''
      },
      loading: false,
      error: null
    }
  },
  methods: {
    handleSignIn() {
      this.loading = true;
      this.error = null;
      
      // Set username to email value since API requires both
      this.credentials.username = this.credentials.email;
      
      fetch('https://api-jobs-dev.talentbase.lk/api/signin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.credentials)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        // Handle successful sign-in
        if (data.access) {
          // Store the access token in a cookie
          this.setAccessTokenCookie(data.access);
          
          // Redirect to profile page
          this.$router.push('/profile');
        } else {
          throw new Error('No access token received');
        }
      })
      .catch(error => {
        this.error = `Sign in failed: ${error.message}`;
        console.error('Sign in error:', error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    
    setAccessTokenCookie(token) {
      // Set cookie to expire in 7 days
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);
      
      // Set the access_token cookie
      document.cookie = `access_token=${token}; expires=${expiryDate.toUTCString()}; path=/; SameSite=Strict`;
    }
  }
}
</script>

<style scoped>
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.signin-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
}

h1 {
  text-align: center;
  margin-bottom: 24px;
  color: #2c3e50;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.signin-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #34495e;
}

input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
}

input:focus {
  border-color: #3498db;
  outline: none;
}

.signin-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signin-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.signin-button:disabled {
  background-color: #a0cae8;
  cursor: not-allowed;
}

.additional-options {
  margin-top: 20px;
  text-align: center;
}

.additional-options a {
  color: #3498db;
  text-decoration: none;
}

.additional-options a:hover {
  text-decoration: underline;
}
</style>