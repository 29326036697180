<template>
  <div class="job-postings-container">
    <h1>Available Job Postings</h1>
    
    <!-- Loading State -->
    <div v-if="isLoading" class="loading">
      Loading job postings...
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <!-- Job Postings List -->
    <div v-else-if="jobPostings.length" class="job-listings">
      <div 
        v-for="job in jobPostings" 
        :key="job.id" 
        class="job-card"
      >
        <div class="job-header">
          <h2>{{ job.title }}</h2>
          <span class="company-name">{{ job.employer_company_name }}</span>
        </div>
        
        <div class="job-details">
          <p><strong>Location:</strong> {{ job.location }}</p>
          <p><strong>Salary:</strong> ${{ formatSalary(job.salary) }}</p>
          <p class="job-description">{{ job.description }}</p>
        </div>
        
        <div class="job-actions">
          <button 
            @click="applyToJob(job.id)"
            :disabled="isApplying[job.id]"
            class="apply-btn"
          >
            {{ isApplying[job.id] ? 'Applying...' : 'Apply Now' }}
          </button>
        </div>
      </div>
    </div>

    <!-- No Jobs State -->
    <div v-else class="no-jobs">
      No job postings available at the moment.
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'JobPostingsList',
  data() {
    return {
      jobPostings: [],
      isLoading: true,
      error: null,
      isApplying: {} // Track applying state for each job
    }
  },
  methods: {
    // Format salary with comma separators
    formatSalary(salary) {
      return parseFloat(salary).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },

    // Fetch job postings
    async fetchJobPostings() {
      try {
        this.isLoading = true;
        const accessToken = Cookies.get('access_token');

        if (!accessToken) {
          throw new Error('No access token found. Please log in.');
        }

        const response = await axios.get('https://api-jobs-dev.talentbase.lk/api/jobposting-list/', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        this.jobPostings = response.data;
        this.error = null;
      } catch (error) {
        console.error('Failed to fetch job postings:', error);
        this.error = error.response?.data?.detail || 'Failed to load job postings';
        this.jobPostings = [];
      } finally {
        this.isLoading = false;
      }
    },

    // Apply to a specific job
    async applyToJob(jobId) {
      try {
        // Directly modify the applying state
        this.isApplying[jobId] = true; // No need for this.$set

        const accessToken = Cookies.get('access_token');

        if (!accessToken) {
          throw new Error('No access token found. Please log in.');
        }

        await axios.post(`https://api-jobs-dev.talentbase.lk/api/jobposting/${jobId}/apply/`, 
          {}, // Empty data object
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // Show success message
        alert('Application submitted successfully!');
      } catch (error) {
        console.error('Job application failed:', error);
        
        // Provide user-friendly error message
        const errorMessage = error.response?.data?.detail || 
          'Failed to submit application. Please try again.';
        alert(errorMessage);
      } finally {
        // Reset applying state
        this.isApplying[jobId] = false; // No need for this.$set
      }
    }
  },
  mounted() {
    // Fetch job postings when component is mounted
    this.fetchJobPostings();
  }
}
</script>


<style scoped>
.job-postings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.job-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.job-header h2 {
  margin: 0;
  color: #333;
}

.company-name {
  color: #666;
  font-size: 0.9em;
}

.job-details p {
  margin: 10px 0;
}

.job-description {
  color: #444;
  line-height: 1.6;
}

.apply-btn {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-btn:hover:not(:disabled) {
  background-color: #45a049;
}

.apply-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading, .error-message, .no-jobs {
  text-align: center;
  padding: 20px;
  color: #666;
}
</style>