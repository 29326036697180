<template>
  <div class="dashboard-container">
     
    <!-- Sidebar: Multi-select Program Filter -->
    <aside class="sidebar">
       <nav class="navbar">
        <router-link to="/dashboard" class="nav-link">Home</router-link>
      </nav>
      <h3>Filters</h3>

      <!-- University selection with auto-complete -->
      <label for="universitySelect">Select University:</label>
      <input 
        id="universitySelect" 
        type="text" 
        v-model="universitySearch" 
        @input="fetchUniversities" 
        placeholder="Search University..."
      />
      <ul v-if="universitySuggestions.length">
        <li 
          v-for="university in universitySuggestions" 
          :key="university.id" 
          @click="selectUniversity(university)"
        >
          {{ university.name }}
        </li>
      </ul>

      <!-- Program selection dropdown -->
      <label for="programSelect">Select Program:</label>
      <select 
        id="programSelect" 
        v-model="selectedProgram" 
        @change="fetchData"
      >
        <option 
          v-for="program in programs" 
          :key="program.id" 
          :value="program.id"
        >
          {{ program.name }}
        </option>
      </select>
    </aside>

    <!-- Pathway Chart -->
    <main class="main-content">
      <section class="pathway-container">
        <h3 class="text-xl font-bold mb-2">Career Pathways</h3>
        <svg ref="pathwaySvg"></svg>
      </section>

      <!-- Alumni Table -->
      <section class="alumni-table mt-8">
        <h3 class="text-xl font-bold mb-2">Alumni Hiring Top Companies</h3>
        <table class="w-full border border-gray-300 rounded-md">
          <thead class="bg-gray-100">
            <tr>
              <th class="p-2 text-left">Company</th>
              <th class="p-2 text-left">Alumni Profiles</th>
              <th class="p-2 text-left">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(company, index) in companies" :key="index" class="border-b">
              <td class="p-2">{{ company.name }}</td>
              <td class="p-2">{{ company.alumni_profiles }}</td>
              <td class="p-2">{{ company.percent }}%</td>
            </tr>
          </tbody>
        </table>
      </section>

       <section class="alumni-table mt-8">
        <h3 class="text-xl font-bold mb-2">Alumni Job Titles</h3>
        <table class="w-full border border-gray-300 rounded-md">
          <thead class="bg-gray-100">
            <tr>
              <th class="p-2 text-left">Job Title</th>
              <th class="p-2 text-left">Profile Count</th>
              <th class="p-2 text-left">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in jobTitles" :key="job.id" class="border-b">
              <td class="p-2">{{ job.title }}</td>
              <td class="p-2">{{ job.profile_count }}</td>
              <td class="p-2">{{ job.profile_percentage }}%</td>
            </tr>
          </tbody>
        </table>
      </section>
      <div class="map-container">
        <h3 class="text-xl font-bold mb-2">Alumni Locations</h3>
        <div id="map" class="map"></div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import * as d3 from 'd3';
import L from "leaflet";


export default {
  data() {
    return {
      universitySearch: '',
      universitySuggestions: [],
      selectedUniversityId: null,
      programs: [],
      selectedProgram: null,
      pathways: [],
      companies: [],
      jobTitles: [],
      alumniLocations: [],
      map: null,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  async mounted() {
    await this.fetchAlumniLocations();
    this.initMap();
  },

  methods: {
    async fetchUniversities() {
      if (this.universitySearch.length < 3) return;
      try {
        const response = await axios.get(`${this.apiUrl}/auto-complete-university/?name=${this.universitySearch}`);
        this.universitySuggestions = response.data;
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    },

    selectUniversity(university) {
      this.selectedUniversityId = university.id;
      this.universitySearch = university.name; // Update input field
      this.universitySuggestions = []; // Hide suggestions
      this.fetchPrograms();
    },

    async fetchPrograms() {
      if (this.selectedUniversityId) {
        try {
          const response = await axios.get(`${this.apiUrl}/alumni/programs/?university_id=${this.selectedUniversityId}`);
          this.programs = response.data;
        } catch (error) {
          console.error('Error fetching programs:', error);
        }
      }
    },

    async fetchData() {
      if (this.selectedUniversityId && this.selectedProgram) {
        await this.fetchPathways();
        await this.fetchCompanies();
        await this.fetchJobTitles();
      }
    },

    async fetchPathways() {
      try {
        const response = await axios.get(`${this.apiUrl}/alumni/mappings/?program_id=${this.selectedProgram}&university_id=${this.selectedUniversityId}`);
        this.pathways = response.data;
        this.drawPathwayChart();
      } catch (error) {
        console.error('Error fetching pathways:', error);
      }
    },

    async fetchCompanies() {
      try {
        const response = await axios.get(`${this.apiUrl}/alumni/companies/?university_id=${this.selectedUniversityId}&program_id=${this.selectedProgram}`);
        this.companies = response.data;
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    },

    async fetchJobTitles() {
      try {
        const response = await axios.get(`${this.apiUrl}/alumni/job-titles/?university_id=${this.selectedUniversityId}&program_id=${this.selectedProgram}`);
        this.jobTitles = response.data;
      } catch (error) {
        console.error('Error fetching job titles:', error);
      }
    },

     async fetchAlumniLocations() {
      try {
        const response = await axios.get(`${this.apiUrl}/alumni/locations/?university_id=1&program_id=1`);
        this.alumniLocations = response.data;
      } catch (error) {
        console.error("Error fetching alumni locations:", error);
      }
    },

    async getCoordinates(city, state, country) {
      try {
        const query = `${city}, ${state}, ${country}`;
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`);
        if (response.data.length > 0) {
          return {
            lat: response.data[0].lat,
            lon: response.data[0].lon
          };
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
      return null;
    },

    async initMap() {
      this.map = L.map("map").setView([7.8731, 80.7718], 7); // Center on Sri Lanka
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      for (const location of this.alumniLocations) {
        const coords = await this.getCoordinates(location.city, location.state, location.country);
        if (coords) {
          L.marker([coords.lat, coords.lon])
            .addTo(this.map)
            .bindPopup(`<b>${location.city}, ${location.state}</b><br>
                        Alumni: ${location.alumni_count}<br>
                        Percentage: ${location.percentage}%`);
        }
      }

    },

     

    drawPathwayChart() {
      const svg = d3.select(this.$refs.pathwaySvg);
      svg.selectAll("*").remove(); // Clear existing SVG before redrawing

      const width = 600, height = 400;
      svg.attr("width", width).attr("height", height);

      const nodes = [];
      const links = [];

      this.pathways.forEach((pathway) => {
        const programNode = { id: pathway.program.name, type: "program" };
        const occupationNode = { id: pathway.occupation.name, type: "occupation" };

        if (!nodes.find(n => n.id === programNode.id)) nodes.push(programNode);
        if (!nodes.find(n => n.id === occupationNode.id)) nodes.push(occupationNode);

        links.push({
          source: programNode.id,
          target: occupationNode.id,
          value: pathway.in_field ? 2 : 1,
        });
      });

      const simulation = d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => d.id).distance(100))
        .force("charge", d3.forceManyBody().strength(-200))
        .force("center", d3.forceCenter(width / 2, height / 2));

      const link = svg.selectAll("line")
        .data(links)
        .enter()
        .append("line")
        .style("stroke", d => (d.value === 2 ? "green" : "gray"))
        .style("stroke-width", d => (d.value === 2 ? 3 : 1));

      const node = svg.selectAll("circle")
        .data(nodes)
        .enter()
        .append("circle")
        .attr("r", 8)
        .style("fill", d => (d.type === "program" ? "blue" : "orange"))
        .call(d3.drag()
          .on("start", dragStart)
          .on("drag", dragged)
          .on("end", dragEnd));

      const labels = svg.selectAll("text")
        .data(nodes)
        .enter()
        .append("text")
        .attr("font-size", "12px")
        .attr("dx", 10)
        .attr("dy", ".35em")
        .text(d => d.id);

      simulation.on("tick", () => {
        link
          .attr("x1", d => d.source.x)
          .attr("y1", d => d.source.y)
          .attr("x2", d => d.target.x)
          .attr("y2", d => d.target.y);

        node
          .attr("cx", d => d.x)
          .attr("cy", d => d.y);

        labels
          .attr("x", d => d.x + 10)
          .attr("y", d => d.y);
      });

      function dragStart(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragEnd(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }
    },
  },

  
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.sidebar {
  width: 250px;
  background: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
}

.main-content {
  flex: 1;
}

.pathway-container {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background: white;
}

svg {
  width: 100%;
  height: 400px;
}


.map-container {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
.map {
  width: 100%;
  height: 100%;
}
</style>
