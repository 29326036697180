// CVExtractor.vue
<template>
  <div class="cv-extractor">
    <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h1 class="text-2xl font-bold text-center mb-6">CV Information Extractor</h1>
      
      <!-- Upload Section -->
      <div class="mb-8">
        <div class="flex items-center justify-center w-full">
          <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
            <div class="flex flex-col items-center justify-center pt-7" v-if="!selectedFile">
              <svg class="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              <p class="pt-1 text-sm tracking-wider text-gray-400">Drag & drop your CV or click to browse</p>
              <p class="text-xs text-gray-500">(PDF format recommended)</p>
            </div>
            <div class="flex flex-col items-center justify-center pt-7" v-else>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <p class="pt-1 text-sm font-semibold text-gray-700">{{ selectedFile.name }}</p>
              <p class="text-xs text-gray-500">{{ formatFileSize(selectedFile.size) }}</p>
            </div>
            <input type="file" class="hidden" @change="handleFileUpload" accept=".pdf,.doc,.docx" ref="fileInput" />
          </label>
        </div>
      </div>
      
      <!-- Button Section -->
      <div class="flex justify-center space-x-4">
        <button 
          @click="clearFile" 
          class="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
          v-if="selectedFile">
          Clear
        </button>
        <button 
          @click="submitCV" 
          class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          :disabled="!selectedFile || loading"
          :class="{'opacity-50 cursor-not-allowed': !selectedFile || loading}">
          {{ loading ? 'Processing...' : 'Extract CV Information' }}
        </button>
      </div>
    </div>
    
    <!-- Loading Indicator -->
    <div v-if="loading" class="flex justify-center items-center my-8">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>

    <!-- Results Section -->
    <div v-if="results.length > 0 && !loading" class="bg-white rounded-lg shadow-lg p-6">
      <h2 class="text-xl font-bold mb-4">Extracted Information</h2>
      
      <div v-for="(result, index) in results" :key="index" class="mb-8 border-b pb-8 last:border-b-0 last:pb-0">
        <div class="bg-gray-50 p-4 rounded-lg mb-4">
          <h3 class="text-lg font-semibold mb-2">{{ result.CV_Filename }}</h3>
          
          <!-- Personal Info -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Personal Information</h4>
              <div class="mt-2">
                <p><span class="font-medium">Name:</span> {{ result.CV_Info_Json['First Name'] }} {{ result.CV_Info_Json['Last Name'] }}</p>
                <p><span class="font-medium">Email:</span> {{ result.CV_Info_Json['Email'] }}</p>
                <p><span class="font-medium">Phone:</span> {{ result.CV_Info_Json['Phone'] }}</p>
                <p v-if="result.CV_Info_Json['Current Residence']"><span class="font-medium">Current Residence:</span> {{ result.CV_Info_Json['Current Residence'] }}</p>
                <p v-if="result.CV_Info_Json['Linkedin']"><span class="font-medium">LinkedIn:</span> 
                  <a :href="result.CV_Info_Json['Linkedin']" target="_blank" class="text-blue-600 hover:underline">
                    {{ result.CV_Info_Json['Linkedin'] }}
                  </a>
                </p>
              </div>
            </div>
            
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Professional Summary</h4>
              <div class="mt-2">
                <p><span class="font-medium">Suitable Position:</span> {{ result.CV_Info_Json['Suitable Position'] }}</p>
                <p><span class="font-medium">Current Status:</span> {{ result.CV_Info_Json['Current Employment Status'] }}</p>
                <p><span class="font-medium">Experience:</span> {{ result.CV_Info_Json['Years of Experience'] }} year(s)</p>
                <p v-if="result.CV_Info_Json['Experience Companies']"><span class="font-medium">Companies:</span> {{ formatArray(result.CV_Info_Json['Experience Companies']) }}</p>
              </div>
            </div>
          </div>
          
          <!-- Education & Skills -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Education</h4>
              <div class="mt-2">
                <div v-if="result.CV_Info_Json['Education Bachelor University']">
                  <p class="font-medium">Bachelor's Degree</p>
                  <p>Major: {{ result.CV_Info_Json['Education Bachelor Major'] }}</p>
                  <p>University: {{ result.CV_Info_Json['Education Bachelor University'] }}</p>
                  <p>Graduation: {{ result.CV_Info_Json['Education Bachelor Graduation Date'] }}</p>
                  <p v-if="result.CV_Info_Json['Education Bachelor GPA']">GPA: {{ result.CV_Info_Json['Education Bachelor GPA'] }}</p>
                </div>
                <div v-if="result.CV_Info_Json['Education Masters University']" class="mt-3">
                  <p class="font-medium">Master's Degree</p>
                  <p>Major: {{ result.CV_Info_Json['Education Masters Major'] }}</p>
                  <p>University: {{ result.CV_Info_Json['Education Masters University'] }}</p>
                  <p>Graduation: {{ result.CV_Info_Json['Education Masters Graduation Date'] }}</p>
                </div>
              </div>
            </div>
            
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Skills</h4>
              <div class="mt-2">
                <p class="font-medium">Technical Skills:</p>
                <ul class="list-disc list-inside">
                  <li v-for="(skill, idx) in getArrayData(result.CV_Info_Json['Top 3 Technical Skills'])" :key="'tech-'+idx">
                    {{ skill }}
                  </li>
                </ul>
                
                <p class="font-medium mt-2">Soft Skills:</p>
                <ul class="list-disc list-inside">
                  <li v-for="(skill, idx) in getArrayData(result.CV_Info_Json['Top 3 Soft Skills'])" :key="'soft-'+idx">
                    {{ skill }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <!-- Projects & Certifications -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Projects</h4>
              <div class="mt-2">
                <ul class="list-disc list-inside">
                  <li v-for="(project, idx) in getArrayData(result.CV_Info_Json['Top 5 Responsibilities/Projects'])" :key="'proj-'+idx">
                    {{ project }}
                  </li>
                </ul>
              </div>
            </div>
            
            <div class="bg-white p-3 rounded shadow">
              <h4 class="font-bold text-blue-700">Certifications</h4>
              <div class="mt-2">
                <ul class="list-disc list-inside">
                  <li v-for="(cert, idx) in getArrayData(result.CV_Info_Json['Top 5 Courses/Certifications'])" :key="'cert-'+idx">
                    {{ cert }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Error Message -->
    <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
      <strong class="font-bold">Error!</strong>
      <span class="block sm:inline"> {{ error }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CVExtractor',
  props: {
    apiUrl: {
      type: String,
      default: 'https://api-dev-cvextraction.talentbase.lk/extract_cv_info'
    }
  },
  data() {
    return {
      selectedFile: null,
      loading: false,
      results: [],
      error: null
    };
  },
  methods: {
    handleFileUpload(e) {
      this.selectedFile = e.target.files[0];
      this.error = null;
    },
    clearFile() {
      this.selectedFile = null;
      this.error = null;
      this.$refs.fileInput.value = '';
    },
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' bytes';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    },
    formatArray(data) {
      if (!data) return '';
      if (Array.isArray(data)) {
        return data.join(', ');
      }
      return data;
    },
    getArrayData(data) {
      if (!data) return [];
      
      // If it's already an array, return it
      if (Array.isArray(data)) {
        return data;
      }
      
      // If it's a string that looks like an array, try to parse it
      if (typeof data === 'string' && (data.startsWith('[') || data.includes("'"))) {
        try {
          // Replace single quotes with double quotes for valid JSON
          const jsonStr = data.replace(/'/g, '"');
          return JSON.parse(jsonStr);
        } catch (e) {
          // If parsing fails, split by commas (fallback)
          return "";
        }
      }
      
      // If it's a simple string, return as single-item array
      return [data];
    },
    async submitCV() {
      if (!this.selectedFile) {
        this.error = 'Please select a file first.';
        return;
      }
      
      this.loading = true;
      this.error = null;
      
      const formData = new FormData();
      formData.append('cs', this.selectedFile);
      
      try {
        const response = await axios.post(this.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        
        this.results = response.data;
        
        if (this.results.length === 0) {
          this.error = 'No information could be extracted from the CV.';
        }
      } catch (error) {
        console.error('Error extracting CV information:', error);
        this.error = 'Failed to extract CV information. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.cv-extractor {
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
}
</style>