<template>


  
  <div class="employer-auth-container">

 <nav class="container mx-auto px-40 py-8">
  <div class="flex justify-center space-x-40"> <!-- Increased space between buttons -->
    <router-link 
      to="/jobpost" 
      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-6 px-12 rounded-lg text-3xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
    >
      Post a Job
    </router-link>
    
    <router-link 
      to="/myjobs" 
      class="bg-green-500 hover:bg-green-600 text-white font-bold py-6 px-12 rounded-lg text-3xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400"
    >
      My Jobs
    </router-link>
  </div>
</nav>
    <div v-if="currentStep === 'signup'" class="signup-form">
      <h2>Employer Signup</h2>
      <form @submit.prevent="handleSignup">
        <div class="form-group">
          <label>Email</label>
          <input 
            type="email" 
            v-model="signupData.email" 
            required 
            placeholder="Enter your email"
          />
        </div>
        <div class="form-group">
          <label>Username</label>
          <input 
            type="text" 
            v-model="signupData.username" 
            required 
            placeholder="Choose a username"
          />
        </div>
        <div class="form-group">
          <label>Password</label>
          <input 
            type="password" 
            v-model="signupData.password" 
            required 
            placeholder="Create a password"
          />
        </div>
        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Signing Up...' : 'Sign Up' }}
        </button>
      </form>
    </div>

    <div v-if="currentStep === 'signin'" class="signin-form">
      <h2>Employer Sign In</h2>
      <form @submit.prevent="handleSignin">
        <div class="form-group">
          <label>Email</label>
          <input 
            type="email" 
            v-model="signinData.email" 
            required 
            placeholder="Enter your email"
          />
        </div>
        <div class="form-group">
          <label>Username</label>
          <input 
            type="text" 
            v-model="signinData.username" 
            required 
            placeholder="Enter your username"
          />
        </div>
        <div class="form-group">
          <label>Password</label>
          <input 
            type="password" 
            v-model="signinData.password" 
            required 
            placeholder="Enter your password"
          />
        </div>
        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Signing In...' : 'Sign In' }}
        </button>
      </form>
    </div>

    <div v-if="currentStep === 'create-profile'" class="employer-profile-form">
      <h2>Create Employer Profile</h2>
      <form @submit.prevent="handleCreateProfile">
        <div class="form-group">
          <label>Company Name</label>
          <input 
            type="text" 
            v-model="profileData.company_name" 
            required 
            placeholder="Enter your company name"
          />
        </div>
        <div class="form-group">
          <label>Company Address</label>
          <input 
            type="text" 
            v-model="profileData.address" 
            required 
            placeholder="Enter company address"
          />
        </div>
        <div class="form-group">
          <label>Company Description</label>
          <textarea 
            v-model="profileData.description" 
            placeholder="Describe your company"
          ></textarea>
        </div>
        <div class="form-group">
          <label>Industry</label>
          <input 
            type="text" 
            v-model="profileData.industry" 
            placeholder="Enter your industry"
          />
        </div>
        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Creating Profile...' : 'Create Profile' }}
        </button>
      </form>
    </div>

    <div v-if="currentStep === 'create-job-posting'" class="job-posting-form">
      <h2>Create Job Posting</h2>
      <form @submit.prevent="handleCreateJobPosting">
        <div class="form-group">
          <label>Job Title</label>
          <input 
            type="text" 
            v-model="jobPostingData.title" 
            required 
            placeholder="Enter job title"
          />
        </div>
        <div class="form-group">
          <label>Job Description</label>
          <textarea 
            v-model="jobPostingData.description" 
            required 
            placeholder="Describe the job responsibilities"
          ></textarea>
        </div>
        <div class="form-group">
          <label>Location</label>
          <input 
            type="text" 
            v-model="jobPostingData.location" 
            required 
            placeholder="Enter job location"
          />
        </div>
        <div class="form-group">
          <label>Salary</label>
          <input 
            type="number" 
            v-model="jobPostingData.salary" 
            required 
            placeholder="Enter salary"
            step="0.01"
          />
        </div>
        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Creating Job Posting...' : 'Create Job Posting' }}
        </button>
      </form>
    </div>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'EmployerAuthComponent',
  data() {
    return {
      currentStep: 'signup',
      isLoading: false,
      error: null,
      signupData: {
        username: '',
        password: '',
        email: '',
        user_type: 'employer'
      },
      signinData: {
        email: '',
        username: '',
        password: ''
      },
      profileData: {
        company_name: '',
        address: '',
        description: '',
        industry: ''
      },
      jobPostingData: {
        title: '',
        description: '',
        location: '',
        salary: null
      }
    }
  },
  created() {
    // Check if access token exists in cookies
    const accessToken = Cookies.get('access_token');
    if (accessToken) {
      this.checkTokenValidity(accessToken);
    }
  },
  methods: {
    async checkTokenValidity(token) {
      try {
        // Implement token validation endpoint
        const response = await axios.get('https://api-jobs-dev.talentbase.lk/api/validate-token/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          // Token is valid, set up for profile or job posting
          this.currentStep = 'create-profile';
        }
      } catch (error) {
        // Token is invalid, remove it and start from signup
        //Cookies.remove('access_token');
        this.currentStep = 'signup';
      }
    },

    async handleSignup() {
      this.isLoading = true;
      this.error = null;

      try {
        const response = await axios.post('https://api-jobs-dev.talentbase.lk/api/signup/', this.signupData);
        
        // Save access token in cookie
        Cookies.set('access_token', response.data.access, { 
          expires: 7, // 7 days
          secure: true,
          sameSite: 'strict' 
        });

        this.currentStep = 'create-profile';
        this.$toast.success('Signup successful! Create your profile.');
      } catch (error) {
        "";
      } finally {
        this.isLoading = false;
      }
    },

    async handleSignin() {
      this.isLoading = true;
      this.error = null;

      try {
        const response = await axios.post('https://api-jobs-dev.talentbase.lk/api/signin/', this.signinData);
        
        // Save access token in cookie
        Cookies.set('access_token', response.data.access, { 
          expires: 7, // 7 days
          secure: true,
          sameSite: 'strict' 
        });

        this.currentStep = 'create-profile';
        this.$toast.success('Signin successful! Create your profile.');
      } catch (error) {
        this.error = error.response?.data?.message || 'Signin failed';
      } finally {
        this.isLoading = false;
      }
    },

    async handleCreateProfile() {
      this.isLoading = true;
      this.error = null;

      // Get access token from cookies
      const accessToken = Cookies.get('access_token');

      try {
        await axios.post('https://api-jobs-dev.talentbase.lk/api/employer/', 
          this.profileData,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.$toast.success('Employer profile created successfully!');
        this.currentStep = 'create-job-posting';
      } catch (error) {
        "";
       
        this.currentStep = 'create-job-posting';
      } finally {
        this.isLoading = false;
      }
    },

    async handleCreateJobPosting() {
      this.isLoading = true;
      this.error = null;

      // Get access token from cookies
      const accessToken = Cookies.get('access_token');

      try {
        const response = await axios.post('https://api-jobs-dev.talentbase.lk/api/jobposting/', 
          this.jobPostingData,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.$toast.success('Job posting created successfully!');
        this.$emit('job-posting-created', response.data);
        
        // Optional: Reset form or navigate
        this.jobPostingData = {
          title: '',
          description: '',
          location: '',
          salary: null
        };
      } catch (error) {
        "";
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
/* Previous styles remain the same */
</style>

<style scoped>
.employer-auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input, 
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.post-job-btn {
  background-color: #2196F3 !important;
  margin-top: 10px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 15px;
  text-align: center;
}
</style>