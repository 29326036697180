import { createRouter, createWebHistory } from 'vue-router';
import JobPostings from '@/components/JobPostings.vue';
import Occupations from '@/components/Occupations.vue';
import AlumniList from '@/components/AlumniList.vue';
import UniversityPrograms from '@/components/UniversityPrograms.vue';
import CareerList from '@/components/CareerList.vue';
import SignUpHome from '@/components/SignUpHome.vue';
import Profile from '@/components/Profile.vue';
import SignIn from '@/components/SignIn.vue';
import Courses from '@/components/Courses.vue';
import JobCards from '@/components/JobCards.vue';
import Cvs from '@/components/Cvs.vue';
import EmployerList from '@/components/EmployerList.vue';
import JobPostingList from '@/components/JobPostingList.vue';


import TBJobsList from '@/components/TBJobsList.vue';
import JobApplyList from '@/components/JobApplyList.vue';









import SignUp from '@/components/SignUp.vue';



const routes = [
  { path: '/', redirect: '/home' },  // Redirect root to /home
  { path: '/dashboard', name: 'Dashboard', component: JobPostings },  // Added name to the route
  { path: '/occupations', name: 'Occupations', component: Occupations },  // Added name to the route
  { path: '/alumni', name: 'AlumniList', component: AlumniList },  // Added name to the route
  { path: '/university', name: 'UniversityPrograms', component: UniversityPrograms },  // Added name to the route
  { path: '/career', name: 'CareerList', component: CareerList },  // Added name to the route
  { path: '/jobseeker-signup', name: 'SignUpHome', component: SignUpHome },  // Added name to the route
  { path: '/profile', name: 'Profile', component: Profile },  // Added name to the route
  { path: '/jobseeker-signin', name: 'SignIn', component: SignIn },  // Added name to the route
  { path: '/courses', name: 'Courses', component: Courses },  // Added name to the route
  { path: '/jobs', name: 'JobCards', component: JobCards }, 
  { path: '/cvs', name: 'Cvs', component: Cvs }, 
  { path: '/employer', name: ' EmployerList', component:  EmployerList }, 
  { path: '/jobpost', name: ' JobPostingList', component:  JobPostingList },
  { path: '/tb-jobs', name: ' TBJobsList', component:  TBJobsList }, 
  { path: '/myjobs', name: ' JobApplyList', component:  JobApplyList }, 





  




  



  { path: '/signup', name: 'SignUp', component: SignUp },  // Added name to the route


  { path: '/home', name: 'Home', component: () => import('@/components/HelloWorld.vue') }  // Example for Home route
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
