<template>
  <div class="profile-container">
    <nav class="navigation">
      <div class="nav-links">
        <router-link to="/" class="nav-link">Home</router-link>
      </div>
      <div class="nav-links">
        <router-link to="/cvs" class="nav-link">Extract and Rate your CV</router-link>
      </div>
      <div class="nav-links">
        <router-link to="/myjobs" class="nav-link">My Jobs</router-link>
      </div>
      
      <div class="nav-actions">
        <button @click="signOut" class="sign-out-btn">Sign Out</button>
      </div>
    </nav>
    
    <div v-if="loading" class="loading">
      <p>Loading profile data...</p>
    </div>
    <div v-else-if="error" class="error">
      <p>{{ error }}</p>
    </div>
    <div v-else class="profile">
      <div class="profile-header">
        <div class="profile-image">
          <img v-if="profile.profile_image" :src="profile.profile_image" alt="Profile Image" />
          <div v-else class="placeholder-image">
            {{ getInitials(profile.first_name, profile.last_name) }}
          </div>
        </div>
        <div class="profile-info">
          <h1>{{ profile.first_name }} {{ profile.last_name }}</h1>
          <p v-if="profile.email"><strong>Email:</strong> {{ profile.email }}</p>
          <p v-if="profile.phone_number"><strong>Phone:</strong> {{ profile.phone_number }}</p>
        </div>
      </div>
      
      <div class="profile-section">
        <h2>Location</h2>
        <p>{{ profile.address }}</p>
        <p>{{ profile.city }}, {{ profile.state }} {{ profile.postal_code }}</p>
      </div>
      
      <div v-if="profile.summary" class="profile-section">
        <h2>Summary</h2>
        <p>{{ profile.summary }}</p>
      </div>
      
      <div v-if="profile.objectives" class="profile-section">
        <h2>Objectives</h2>
        <p>{{ profile.objectives }}</p>
      </div>
      
      <div class="profile-section">
        <h2>Resume</h2>
        <div v-if="profile.resume">
          <a :href="getResumeUrl(profile.resume)" target="_blank">View Resume</a>
          <p v-if="profile.resume_approval_status">
            <strong>Status:</strong> {{ formatStatus(profile.resume_approval_status) }}
          </p>
          <p v-if="profile.resume_feedback">
            <strong>Feedback:</strong> {{ profile.resume_feedback }}
          </p>
        </div>
        <p v-else>No resume uploaded</p>
      </div>
      
      <div class="profile-section">
        <h2>Experience</h2>
        <div v-if="profile.experience && profile.experience.length > 0">
          <div v-for="(exp, index) in profile.experience" :key="index" class="experience-item">
            <h3>{{ exp.position }} at {{ exp.company }}</h3>
            <p>{{ formatDateRange(exp.start_date, exp.end_date) }}</p>
            <p>{{ exp.description }}</p>
          </div>
        </div>
        <p v-else>No experience added yet</p>
      </div>
      
      <div class="profile-section">
        <h2>Education</h2>
        <div v-if="profile.education && profile.education.length > 0">
          <div v-for="(edu, index) in profile.education" :key="index" class="education-item">
            <h3>{{ edu.degree }} - {{ edu.institution }}</h3>
            <p>{{ formatDateRange(edu.start_date, edu.end_date) }}</p>
            <p v-if="edu.description">{{ edu.description }}</p>
          </div>
        </div>
        <p v-else>No education added yet</p>
      </div>
      
      <div class="profile-section">
        <h2>Skills</h2>
        <div v-if="profile.skills && profile.skills.length > 0" class="skills-list">
          <span v-for="(skill, index) in profile.skills" :key="index" class="skill-tag">
            {{ skill.name }}
          </span>
        </div>
        <p v-else>No skills added yet</p>
      </div>
      
      <div class="profile-section">
        <h2>Social Links</h2>
        <div class="social-links">
          <a v-if="profile.linkedin_link" :href="profile.linkedin_link" target="_blank">LinkedIn</a>
          <a v-if="profile.twitter_link" :href="profile.twitter_link" target="_blank">Twitter</a>
          <a v-if="profile.facebook_link" :href="profile.facebook_link" target="_blank">Facebook</a>
          <a v-if="profile.whatsapp_link" :href="profile.whatsapp_link" target="_blank">WhatsApp</a>
        </div>
      </div>
      
      <div v-if="profile.recommended_jobs && profile.recommended_jobs.length > 0" class="profile-section">
        <h2>Recommended Jobs</h2>
        <div v-for="(job, index) in profile.recommended_jobs" :key="index" class="job-item">
          <h3>{{ job.title }}</h3>
          <p>{{ job.company }}</p>
        </div>
      </div>
      
      <div v-if="profile.saved_jobs && profile.saved_jobs.length > 0" class="profile-section">
        <h2>Saved Jobs</h2>
        <div v-for="(job, index) in profile.saved_jobs" :key="index" class="job-item">
          <h3>{{ job.title }}</h3>
          <p>{{ job.company }}</p>
        </div>
      </div>
      
      <div v-if="profile.recommended_courses && profile.recommended_courses.length > 0" class="profile-section">
        <h2>Recommended Courses</h2>
        <div v-for="(course, index) in profile.recommended_courses" :key="index" class="course-item">
          <h3>{{ course.title }}</h3>
          <p>{{ course.provider }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePage',
  data() {
    return {
      profile: {},
      loading: true,
      error: null,
      apiBaseUrl: 'https://api-jobs-dev.talentbase.lk'
    }
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    fetchProfile() {
      this.loading = true;
      this.error = null;
      
      // Get access token from cookies
      const accessToken = this.getAccessTokenFromCookie();
      
      if (!accessToken) {
        this.error = "No access token found. Please login again.";
        this.loading = false;
        return;
      }
      
      fetch(`${this.apiBaseUrl}/api/profile/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        this.profile = data;
        this.loading = false;
      })
      .catch(error => {
        this.error = `Failed to load profile: ${error.message}`;
        this.loading = false;
        console.error('Error fetching profile:', error);
      });
    },
    
    getAccessTokenFromCookie() {
      // Get all cookies
      const cookies = document.cookie.split(';');
      
      // Find the access_token cookie
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('access_token=')) {
          return cookie.substring('access_token='.length, cookie.length);
        }
      }
      
      // Return null if cookie not found
      return null;
    },
    
    getInitials(firstName, lastName) {
      return (firstName ? firstName.charAt(0) : '') + (lastName ? lastName.charAt(0) : '');
    },
    
    formatStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
    
    formatDateRange(startDate, endDate) {
      let formattedStart = startDate ? new Date(startDate).toLocaleDateString() : '';
      let formattedEnd = endDate ? new Date(endDate).toLocaleDateString() : 'Present';
      
      return `${formattedStart} - ${formattedEnd}`;
    },
    
    getResumeUrl(resumePath) {
      // If the path already has the base URL, return it unchanged
      if (resumePath.startsWith('http')) {
        return resumePath;
      }
      
      // Otherwise, prepend the base URL
      return `${this.apiBaseUrl}${resumePath}`;
    },
    
    signOut() {
      // Clear the access token cookie
      document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      
      // Redirect to login page
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.nav-link {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
  padding: 5px 10px;
}

.nav-link:hover {
  text-decoration: underline;
}

.sign-out-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.sign-out-btn:hover {
  background-color: #c0392b;
}

.loading, .error {
  text-align: center;
  padding: 20px;
}

.error {
  color: #e74c3c;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.profile-image {
  margin-right: 20px;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.placeholder-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3498db;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}

.profile-section {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.profile-section:last-child {
  border-bottom: none;
}

.social-links a {
  margin-right: 15px;
  text-decoration: none;
  color: #3498db;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
}

.skill-tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.experience-item, .education-item, .job-item, .course-item {
  margin-bottom: 15px;
}

h1 {
  margin: 0 0 10px 0;
}

h2 {
  color: #2c3e50;
  margin-bottom: 15px;
}

h3 {
  margin: 0 0 5px 0;
  color: #34495e;
}
</style>