<template>
  <div class="registration-container">
    <!-- Step 1: Signup Form -->
    <div v-if="currentStep === 1" class="form-container">
      <h2>Create Your Account</h2>
      <div v-if="signupError" class="error-message">{{ signupError }}</div>
      
      <form @submit.prevent="handleSignup" class="form">
        <div class="form-group">
          <label for="email">Email</label>
          <input 
            type="email" 
            id="email" 
            v-model="signupData.email" 
            required
            placeholder="Enter your email"
          >
        </div>
        
        <div class="form-group">
          <label for="password">Password</label>
          <input 
            type="password" 
            id="password" 
            v-model="signupData.password" 
            required
            placeholder="Enter your password"
          >
        </div>
        
        <div class="form-group">
          <label>User Type</label>
          <div class="radio-group">
            <label>
              <input type="radio" v-model="signupData.userType" value="jobseeker">
              Job Seeker
            </label>
            <label>
              <input type="radio" v-model="signupData.userType" value="employer">
              Employer
            </label>
          </div>
        </div>
        
        <button 
          type="submit" 
          class="submit-button" 
          :disabled="isLoading"
        >
          {{ isLoading ? 'Creating Account...' : 'Create Account' }}
        </button>
      </form>
    </div>
    
    <!-- Step 2: Profile Information and CV Upload -->
    <div v-if="currentStep === 2" class="form-container">
      <h2>Complete Your Profile</h2>
      <div v-if="profileError" class="error-message">{{ profileError }}</div>
      <div v-if="profileSuccess" class="success-message">{{ profileSuccess }}</div>
      
      <form @submit.prevent="handleProfileSubmit" class="form" enctype="multipart/form-data">
        <div class="form-row">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input 
              type="text" 
              id="firstName" 
              v-model="profileData.firstName" 
              required
              placeholder="Enter your first name"
            >
          </div>
          
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input 
              type="text" 
              id="lastName" 
              v-model="profileData.lastName" 
              required
              placeholder="Enter your last name"
            >
          </div>
        </div>
        
        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input 
            type="tel" 
            id="phone" 
            v-model="profileData.phoneNumber" 
            required
            placeholder="Enter your phone number"
          >
        </div>
        
        <div class="form-group">
          <label for="address">Address</label>
          <input 
            type="text" 
            id="address" 
            v-model="profileData.address" 
            required
            placeholder="Enter your street address"
          >
        </div>
        
        <div class="form-row">
          <div class="form-group">
            <label for="city">City</label>
            <input 
              type="text" 
              id="city" 
              v-model="profileData.city" 
              required
              placeholder="Enter your city"
            >
          </div>
          
          <div class="form-group">
            <label for="state">State/Province</label>
            <input 
              type="text" 
              id="state" 
              v-model="profileData.state" 
              required
              placeholder="Enter your state or province"
            >
          </div>
        </div>
        
        <div class="form-group">
          <label for="resume">Upload Resume/CV (PDF)</label>
          <div class="file-upload">
            <input 
              type="file" 
              id="resume" 
              ref="resumeUpload"
              @change="handleFileChange" 
              accept=".pdf,.doc,.docx"
            >
            <div class="upload-status" v-if="fileSelected">
              {{ profileData.resume.name }}
            </div>
          </div>
        </div>
        
        <button 
          type="submit" 
          class="submit-button" 
          :disabled="isLoading"
        >
          {{ isLoading ? 'Submitting Profile...' : 'Complete Registration' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegistrationFlow',
  data() {
    return {
      currentStep: 1,
      isLoading: false,
      fileSelected: false,
      
      // Signup Data
      signupData: {
        email: '',
        password: '',
        userType: 'jobseeker'
      },
      signupError: '',
      
      // Profile Data
      profileData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address: '',
        city: '',
        state: '',
        resume: null
      },
      profileError: '',
      profileSuccess: '',
      
      // Auth tokens
      accessToken: '',
      refreshToken: ''
    };
  },
  methods: {
    async handleSignup() {
      this.isLoading = true;
      this.signupError = '';
      
      try {
        const response = await axios.post('https://api-jobs-dev.talentbase.lk/api/signup/', {
          username: this.signupData.email,
          email: this.signupData.email,
          password: this.signupData.password,
          user_type: this.signupData.userType
        });
        
        // Extract tokens from response
        const { access, refresh, user_type } = response.data;
        
        // Save tokens
        this.accessToken = access;
        this.refreshToken = refresh;
        
        // Save tokens in cookies
        this.setCookie('access_token', access, 7);
        this.setCookie('refresh_token', refresh, 30);
        this.setCookie('user_type', user_type, 30);
        
        // Store user info in localStorage
        localStorage.setItem('user_email', this.signupData.email);
        localStorage.setItem('user_type', user_type);
        
        // Move to profile completion step
        this.currentStep = 2;
      } catch (err) {
        console.error('Signup error:', err);
        this.signupError = err.response?.data?.message || 'Failed to create account. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },
    
    handleFileChange(event) {
      this.profileData.resume = event.target.files[0];
      this.fileSelected = true;
    },
    
    async handleProfileSubmit() {
      this.isLoading = true;
      this.profileError = '';
      this.profileSuccess = '';
      
      try {
        // Create form data for multipart/form-data request (for file upload)
        const formData = new FormData();
        formData.append('first_name', this.profileData.firstName);
        formData.append('last_name', this.profileData.lastName);
        formData.append('email', this.signupData.email);
        formData.append('phone_number', this.profileData.phoneNumber);
        formData.append('address', this.profileData.address);
        formData.append('city', this.profileData.city);
        formData.append('state', this.profileData.state);
        
        // Append file if selected
        if (this.profileData.resume) {
          formData.append('resume', this.profileData.resume);
        }
        
        // Send profile data with uploaded CV
        await axios.post(
          'https://api-jobs-dev.talentbase.lk/api/profile/',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${this.accessToken}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        
        this.profileSuccess = 'Profile created successfully! Redirecting to dashboard...';
        
        // Redirect to dashboard after successful profile creation
        setTimeout(() => {
          this.$router.push('/profile');
        }, 2000);
        
      } catch (err) {
        console.error('Profile creation error:', err);
        this.profileError = err.response?.data?.message || 'Failed to create profile. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },
    
    // Helper function to set cookies with expiry
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "; expires=" + date.toUTCString();
      document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Strict";
    }
  }
}
</script>

<style scoped>
.registration-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
}

.form-container {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label {
  font-weight: 600;
  font-size: 14px;
  color: #555;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #4CAF50;
  outline: none;
}

.radio-group {
  display: flex;
  gap: 20px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: normal;
  cursor: pointer;
}

.file-upload {
  border: 1px dashed #ddd;
  padding: 15px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.upload-status {
  margin-top: 10px;
  font-size: 14px;
  color: #4CAF50;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 14px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 20px;
  }
}
</style>