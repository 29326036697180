<template>
  <div class="employer-applicants-container">
    <h1>My Job Postings and Applicants</h1>
    
    <!-- Loading State -->
    <div v-if="isLoading" class="loading">
      Loading job postings...
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <!-- Job Postings with Applicants -->
    <div v-else-if="jobPostings.length" class="job-postings-list">
      <div 
        v-for="job in jobPostings" 
        :key="job.id" 
        class="job-posting-card"
      >
        <div class="job-header">
          <div>
            <h2>{{ job.title }}</h2>
            <p class="job-details">
              <span>Location: {{ job.location }}</span> | 
              <span>Salary: ${{ formatSalary(job.salary) }}</span>
            </p>
          </div>
        </div>
        
        <div class="applicants-section">
          <h3>Applicants ({{ job.applicants.length }})</h3>
          
          <!-- No Applicants State -->
          <div v-if="job.applicants.length === 0" class="no-applicants">
            No applicants yet for this job posting.
          </div>
          
          <!-- Applicants List -->
          <div 
            v-else 
            v-for="applicant in job.applicants" 
            :key="applicant.id" 
            class="applicant-card"
          >
            <div class="applicant-info">
              <h4>{{ applicant.first_name }} {{ applicant.last_name }}</h4>
              
              <!-- CV View Button -->
              <div class="applicant-actions">
                <button 
                  @click="viewCV(applicant.resume)"
                  class="view-cv-btn"
                >
                  View CV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Job Postings State -->
    <div v-else class="no-job-postings">
      You have no job postings at the moment.
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'EmployerJobApplicants',
  setup() {
    const jobPostings = ref([]);
    const isLoading = ref(true);
    const error = ref(null);

    // Format salary with comma separators
    const formatSalary = (salary) => {
      return parseFloat(salary).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    };

    // Fetch job postings with applicants
    const fetchJobPostingsWithApplicants = async () => {
      try {
        isLoading.value = true;
        const accessToken = Cookies.get('access_token');

        if (!accessToken) {
          throw new Error('No access token found. Please log in.');
        }

        const response = await axios.get('https://api-jobs-dev.talentbase.lk/api/jobposting/', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        jobPostings.value = response.data;
        error.value = null;
      } catch (err) {
        console.error('Failed to fetch job postings:', err);
        error.value = err.response?.data?.detail || 'Failed to load job postings';
        jobPostings.value = [];
      } finally {
        isLoading.value = false;
      }
    };

    // View CV in new tab
    const viewCV = (cvUrl) => {
      if (cvUrl) {
        window.open(cvUrl, '_blank');
      } else {
        alert('CV is not available.');
      }
    };

    // Fetch job postings when component is mounted
    onMounted(fetchJobPostingsWithApplicants);

    return {
      jobPostings,
      isLoading,
      error,
      formatSalary,
      fetchJobPostingsWithApplicants,
      viewCV
    };
  }
}
</script>

<style scoped>
.employer-applicants-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.job-posting-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.job-header h2 {
  margin: 0 0 10px 0;
}

.job-details {
  color: #666;
  margin: 0;
  font-size: 0.9em;
}

.job-details span {
  margin-right: 10px;
}

.applicants-section {
  margin-top: 15px;
}

.applicant-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
}

.applicant-info h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.applicant-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.view-cv-btn {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-cv-btn:hover {
  background-color: #1976D2;
}

.loading, .error-message, .no-job-postings, .no-applicants {
  text-align: center;
  padding: 20px;
  color: #666;
}
</style>