<template>
  <div class="dashboard">
    <div class="left-menu">
      <nav class="navbar">
        <router-link to="/dashboard" class="nav-link">Home</router-link>
      </nav>
      <div class="menu-item" v-for="item in menuItems" :key="item">
        {{ item }}
      </div>
    </div>

    <div class="main-content">
      <div class="filters">
        <h3>1. Occupation</h3>
        <input 
          type="text" 
          v-model="occupation" 
          placeholder="Ex: Web Developer,Web Designer"
          @input="fetchOccupations"
        />
        <ul v-if="occupations.length" class="autocomplete-list">
          <li 
            v-for="occ in occupations" 
            :key="occ.id" 
            @click="selectOccupation(occ)"
          >
            {{ occ.name }}
          </li>
        </ul>

        <h3>2. MSA</h3>
        <input type="text" v-model="msa" placeholder="Add Regions" />

        <h3>Timeframe</h3>
        <div class="timeframe-inputs">
          <input type="number" v-model="timeframe.start" placeholder="From" />
          <input type="number" v-model="timeframe.end" placeholder="To" />
        </div>

        <h3>Class of Worker</h3>
        <div class="checkbox-group">
          <label><input type="checkbox" v-model="classOfWorker.qcew" /> QCEW Employees</label>
          <label><input type="checkbox" v-model="classOfWorker.nonQcew" /> Non-QCEW Employees</label>
          <label><input type="checkbox" v-model="classOfWorker.selfEmployed" /> Self-Employed</label>
          <label><input type="checkbox" v-model="classOfWorker.extended" /> Extended Proprietors</label>
        </div>
      </div>

      <!-- Specialized Occupation Details -->
      <div class="specialized-occupation">
        <h3>Specialized Occupation Details</h3>
        <div v-if="specializedOccupation">
          <p><strong>Name:</strong> {{ specializedOccupation.name }}</p>
          <p><strong>Description:</strong> {{ specializedOccupation.description }}</p>
          <p><strong>Industry:</strong> {{ specializedOccupation.industry }}</p>
        </div>
        <div v-else>
          <p>Loading occupation details...</p>
        </div>
      </div>
      
      <!-- Skills chart section -->
      <div class="skills-chart">
        <h3>Top Specialized Skills</h3>
        <div class="button-group">
          <button @click="changeTab('specialized')" :class="{ active: selectedTab === 'specialized' }">
            Specialized Skills
          </button>
          <button @click="changeTab('common')" :class="{ active: selectedTab === 'common' }">
            Common Skills
          </button>
          <button @click="changeTab('software')" :class="{ active: selectedTab === 'software' }">
            Software Skills
          </button>
        </div>

        <canvas id="skillsChart"></canvas>
      </div>

      <!-- Job Trends chart section -->
      <div class="job-trends-chart">
        <h3>Projected Occupation Growth Over Time</h3>
        <canvas id="jobTrendsChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; 
import Chart from 'chart.js/auto';

export default {
  name: 'OccupationsPage',  // Updated to a multi-word component name

  data() {
    return {
      occupation: '',
      msa: '',
      timeframe: { start: 2022, end: 2032 },
      classOfWorker: {
        qcew: true,
        nonQcew: false,
        selfEmployed: false,
        extended: false,
      },
      selectedTab: 'specialized',
      occupations: [],
      specializedOccupation: null,  // Store specialized occupation details
      skillChartInstance: null,     // Store skill chart instance for cleanup
      menuItems: ["Dashboard", "Analytics", "Reports", "Settings"]
    };
  },
  mounted() {
    this.loadFromLocalStorage(); // Load occupation ID from localStorage if available
    this.renderJobTrendsChart();
  },
  watch: {
    occupation(newVal) {
      if (newVal) {
        this.fetchOccupations();
      }
    }
  },
  methods: {
    fetchOccupations() {
      if (this.occupation.length >= 3) {
        axios
          .get(`https://api-jobs-dev.talentbase.lk/api/auto-complete-speicalizedoccupation/?search=${this.occupation}`)
          .then(response => {
            this.occupations = response.data;
          })
          .catch(error => {
            console.error('Error fetching occupations:', error);
          });
      } else {
        this.occupations = [];
      }
    },
    selectOccupation(occupation) {
      this.occupation = occupation.name;
      this.occupations = [];
      this.fetchSpecializedOccupation(occupation.id);  // Fetch specialized occupation details when an occupation is selected
      this.saveToLocalStorage(occupation.id);  // Save occupation ID to localStorage
    },
    fetchSpecializedOccupation(occupationId) {
      axios
        .get(`https://api-jobs-dev.talentbase.lk/api/specialized-occupation/${occupationId}/`)
        .then(response => {
          this.specializedOccupation = response.data;
          this.renderChart();  // Re-render the chart after fetching the data
        })
        .catch(error => {
          console.error('Error fetching specialized occupation:', error);
        });
    },
    loadFromLocalStorage() {
      const occupationId = localStorage.getItem('selectedOccupationId');
      if (occupationId) {
        this.fetchSpecializedOccupation(occupationId);  // Fetch from API if ID is stored
      }
    },
    saveToLocalStorage(occupationId) {
      localStorage.setItem('selectedOccupationId', occupationId);  // Save selected occupation ID to localStorage
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
    renderChart() {
      if (!this.specializedOccupation) return;

      // Destroy existing chart instance to prevent duplicate rendering
      if (this.skillChartInstance) {
        this.skillChartInstance.destroy();
      }

      const ctx = document.getElementById('skillsChart').getContext('2d');
      const skillNames = this.specializedOccupation.skills.map(skill => skill.name);
      const numJobs = this.specializedOccupation.skills.map(skill => skill.statistics.num_jobs);
      const numProfiles = this.specializedOccupation.skills.map(skill => skill.statistics.num_profiles);

      this.skillChartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: skillNames,
          datasets: [
            {
              label: 'Job Postings',
              data: numJobs,
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
            {
              label: 'Profiles',
              data: numProfiles,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: { stacked: false },
            y: { beginAtZero: true },
          },
        }
      });
    },
    renderJobTrendsChart() {
      const ctx = document.getElementById('jobTrendsChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: [2022, 2023, 2024, 2025, 2026],
          datasets: [
            {
              label: 'National Jobs',
              data: [500000, 510000, 520000, 530000, 540000],
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: true,
              tension: 0.4
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: { type: 'category' },
            y: { beginAtZero: true },
          },
        }
      });
    }
  }
};
</script>




<style scoped>
.dashboard {
  display: flex;
  gap: 20px;
  font-family: Arial, sans-serif;
}

.left-menu {
  background-color: #f5f5f5;  /* Light grey background */
  padding: 20px;
  width: 250px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #ffffff;
  transition: all 0.3s ease;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  text-align: center;
}

.menu-item:hover {
  background: #e0e0e0;
}

/* Filters section */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h3 {
  margin-bottom: 10px;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.timeframe-inputs input {
  width: 48%;
  display: inline-block;
  margin-right: 4%;
}

.timeframe-inputs input:last-child {
  margin-right: 0;
}

.checkbox-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}

/* Button styles */
.button-group {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

button {
  padding: 12px 20px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

button:hover {
  background: #0056b3;
}

button.active {
  background: #004080;
}

/* Chart container */
.skills-chart,
.job-trends-chart {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}
</style>