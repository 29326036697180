<script setup>
import { ref } from "vue";
import axios from "axios";

const universityId = ref("");
const programs = ref([]);
const loading = ref(false);
const error = ref(null);

const fetchPrograms = async () => {
  if (!universityId.value) {
    error.value = "Please enter a university ID.";
    return;
  }

  loading.value = true;
  error.value = null;
  programs.value = [];

  try {
    const response = await axios.get(`http://localhost:8000/api/alumni/programs/?university_id=${universityId.value}`);
    programs.value = response.data;
  } catch (err) {
    error.value = "Failed to fetch data.";
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <div class="p-4">
    <h2 class="text-lg font-semibold mb-2">Fetch Alumni Programs</h2>

    <div class="mb-4">
      <label for="universityId" class="block text-sm font-medium">University ID:</label>
      <input
        v-model="universityId"
        type="number"
        id="universityId"
        class="border rounded p-2 w-full mt-1"
        placeholder="Enter university ID"
      />
      <button
        @click="fetchPrograms"
        class="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Fetch Programs
      </button>
    </div>

    <div v-if="loading">Loading...</div>
    <div v-else-if="error" class="text-red-500">{{ error }}</div>

    <ul v-else-if="programs.length" class="list-disc pl-5">
      <li v-for="program in programs" :key="program.id">
        <strong>{{ program.name }}</strong> (CIP Code: {{ program.cip_code }})
      </li>
    </ul>
    <div v-else>No programs found.</div>
  </div>
</template>
