<template>
  <div class="courses-page">
    <!-- Added Header Component -->
    <HeaderList />
    
    <!-- Header Section with Curved Bottom -->
    <div class="header-section">
      <h1>Courses</h1>
      <div class="breadcrumb">
        <a href="#">HOME</a>
        <span class="separator">></span>
        <span class="current">COURSES</span>
      </div>
    </div>
    
    <!-- Filter and Search Section -->
    <div class="filter-section">
      <div class="container">
        <div class="search-filters">
          <div class="filter-input">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="Keywords, Course..." v-model="searchKeyword">
          </div>
          
          <div class="filter-select">
            <i class="fas fa-briefcase"></i>
            <select v-model="selectedCategory">
              <option value="">All Categories</option>
              <option value="Accounting">Accounting</option>
              <option value="Data Analyst">Data Analyst</option>
              <option value="Product Director">Product Director</option>
            </select>
          </div>
          
          <div class="filter-duration">
            <i class="fas fa-clock"></i>
            <select v-model="selectedDuration">
              <option value="">Duration</option>
              <option value="1 Year">1 Year</option>
              <option value="2 Years">2 Years</option>
              <option value="3+ Years">3+ Years</option>
            </select>
          </div>
          
          <button class="search-btn" @click="searchCourses">
            <i class="fas fa-search"></i> SEARCH
          </button>
        </div>
        
        <div class="view-options">
          <button class="view-btn" :class="{ active: viewType === 'list' }" @click="viewType = 'list'">
            <i class="fas fa-list"></i> LIST VIEW
          </button>
          <button class="view-btn" :class="{ active: viewType === 'grid' }" @click="viewType = 'grid'">
            <i class="fas fa-th-large"></i> GRID VIEW
          </button>
        </div>
      </div>
    </div>
    
    <!-- Featured Courses Section -->
    <div class="courses-section">
      <div class="container">
        <h2 class="section-title">Featured Courses</h2>
        
        <div class="courses-list" :class="{ 'grid-view': viewType === 'grid' }">
          <div v-for="course in filteredCourses" 
               :key="course.id" 
               class="course-card"
               @click="goToCourseUrl(course.url)"
               :style="{ cursor: 'pointer' }">
            <div class="course-tag" v-if="course.id === 2">FREE</div>
            <div class="course-header">
              <div class="course-logo">
                <img :src="course.logo || 'https://via.placeholder.com/80'" :alt="course.institute">
              </div>
              <div class="course-title">
                <h3>{{ course.occupation }}</h3>
                <p>{{ course.institute }}</p>
              </div>
              <div class="favorite" @click.stop="toggleFavorite(course)">
                <i class="far fa-heart"></i>
              </div>
            </div>
            
            <div class="course-description">
              <p>{{ truncateDescription(course.description) }}</p>
            </div>
            
            <div class="course-details">
              <span class="course-type">
                <i class="fas fa-graduation-cap"></i> Type: {{ course.mode || 'Degree' }}
              </span>
              <span class="course-duration">
                <i class="fas fa-clock"></i> Duration: {{ getDuration(course) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Header component
import HeaderList from '@/components/HeaderList';

export default {
  name: 'CoursesPage',
  // Register Header component
  components: {
    HeaderList
  },
  data() {
    return {
      courses: [],
      searchKeyword: '',
      selectedCategory: '',
      selectedDuration: '',
      viewType: 'list',
      loading: true,
      error: null,
      favorites: []
    }
  },
  computed: {
    filteredCourses() {
      let filtered = this.courses;
      
      if (this.searchKeyword) {
        const keyword = this.searchKeyword.toLowerCase();
        filtered = filtered.filter(course => 
          course.course_name.toLowerCase().includes(keyword) || 
          course.occupation.toLowerCase().includes(keyword) ||
          course.institute.toLowerCase().includes(keyword)
        );
      }
      
      if (this.selectedCategory) {
        filtered = filtered.filter(course => 
          course.occupation === this.selectedCategory
        );
      }
      
      // Add duration filter logic when applicable
      
      return filtered;
    }
  },
  methods: {
    fetchCourses() {
      this.loading = true;
      fetch('https://api-jobs-dev.talentbase.lk/api/courses/')
        .then(response => response.json())
        .then(data => {
          this.courses = data;
          this.loading = false;
        })
        .catch(error => {
          this.error = "Failed to load courses. Please try again.";
          this.loading = false;
          console.error('Error fetching courses:', error);
        });
    },
    searchCourses() {
      // Trigger any additional search logic here
      console.log('Searching with:', this.searchKeyword, this.selectedCategory, this.selectedDuration);
    },
    truncateDescription(description) {
      return description.length > 150 ? description.substring(0, 150) + '...' : description;
    },
    getDuration(course) {
      // Extract or provide default duration
      return course.mode === 'Full time' ? '3 Years' : '2 Years';
    },
    goToCourseUrl(url) {
      if (url) {
        // Open in a new tab
        window.open(url, '_blank');
        
        // Alternative: Navigate within the same window
        // window.location.href = url;
      }
    },
    toggleFavorite(course) {
      // Prevent the click from navigating to the course URL
      // This method now uses event.stop in the template
      
      if (this.favorites.includes(course.id)) {
        this.favorites = this.favorites.filter(id => id !== course.id);
      } else {
        this.favorites.push(course.id);
      }
      
      // Optionally save favorites to localStorage
      localStorage.setItem('favoriteCourses', JSON.stringify(this.favorites));
    }
  },
  created() {
    this.fetchCourses();
    
    // Load favorites from localStorage if available
    const savedFavorites = localStorage.getItem('favoriteCourses');
    if (savedFavorites) {
      try {
        this.favorites = JSON.parse(savedFavorites);
      } catch (e) {
        console.error('Error loading favorites:', e);
      }
    }
  }
}
</script>

<style scoped>
/* Global styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Header Section */
.header-section {
  background-color: #ee7f22;
  color: white;
  padding: 40px 0 80px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.header-section h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.breadcrumb a {
  color: white;
  text-decoration: none;
}

.separator {
  font-size: 12px;
}

/* Create curved bottom edge */
.header-section:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: white;
  border-radius: 50% 50% 0 0;
  transform: scaleX(1.5);
}

/* Filter Section */
.filter-section {
  margin-top: -50px;
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
}

.search-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.filter-input, .filter-select, .filter-duration {
  position: relative;
  flex: 1;
}

.filter-input i, .filter-select i, .filter-duration i {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.filter-input input, .filter-select select, .filter-duration select {
  width: 100%;
  padding: 12px 15px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
}

.search-btn {
  background-color: #ee7f22;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-options {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.view-btn {
  background-color: #ee7f22;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}

.view-btn.active {
  background-color: #d6711e;
}

/* Courses Section */
.section-title {
  font-size: 24px;
  color: #405066;
  margin-bottom: 20px;
}

.courses-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.courses-list.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.course-card {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.course-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.course-tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ee7f22;
  color: white;
  padding: 5px 15px;
  transform: rotate(-45deg) translate(-15px, -10px);
  font-size: 12px;
  font-weight: 600;
}

.course-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.course-logo {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.course-logo img {
  max-width: 100%;
  max-height: 100%;
}

.course-title {
  flex: 1;
}

.course-title h3 {
  font-size: 18px;
  color: #405066;
  margin-bottom: 5px;
}

.course-title p {
  font-size: 14px;
  color: #888;
}

.favorite {
  color: #ee7f22;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.favorite:hover {
  background-color: rgba(238, 127, 34, 0.1);
}

.course-description {
  margin-bottom: 15px;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.course-details {
  display: flex;
  gap: 20px;
  font-size: 13px;
  color: #555;
}

.course-type, .course-duration {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
  .search-filters {
    flex-direction: column;
  }
  
  .courses-list.grid-view {
    grid-template-columns: 1fr;
  }
  
  .course-details {
    flex-direction: column;
    gap: 10px;
  }
}
</style>